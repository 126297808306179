import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors'

export default function App() {

  const apiKey = "2232448"
  const greyTheme = createTheme({ palette: { primary: grey } })

  const minimumWage = {
    code: "MA000000",
    fullclassification: "Award/Agreement Free",
    base_rate: "24.10",
    base_rate_type: "Hourly"
  }

  const fmwAllowance = {
    code: "MA000000",
    fullallowance: "-",
    allowance_amount: "-",
    payment_frequency: "-"
  }

  const cpi2025 = [
    {
      code: "AG",
      group: "All Groups",
      rate: 3.6
    },
    {
      code: "FNAB",
      group: "Food and non-alcoholic beverages",
      rate: 3.8
    },
    {
      code: "AT",
      group: "Alcohol and tobacco",
      rate: 6.3
    },
    {
      code: "CF",
      group: "Clothing and Footwear",
      rate: 0.4
    },
    {
      code: "HO",
      group: "Housing",
      rate: 4.9
    },
    {
      code: "FHES",
      group: "Furnishings, household equipment and services",
      rate: 0.2
    },
    {
      code: "HE",
      group: "Health",
      rate: 4.1
    },
    {
      code: "TRA",
      group: "Transport",
      rate: 3.6
    },
    {
      code: "COM",
      group: "Communication",
      rate: 1.8
    },
    {
      code: "RC",
      group: "Recreation and culture",
      rate: 0.2
    },
    {
      code: "EDU",
      group: "Education",
      rate: 5.2
    },
    {
      code: "IFS",
      group: "Insurance and financial services",
      rate: 8.2
    }
  ]

  //const thisYear = new Date().getFullYear().toString()
  const thisYear = "2024"
  const [loading, setLoading] = useState(true)
  const [MAList, setMAList] = useState([])
  const [MACode, setMACode] = useState("MA000000")
  const [c13, setC13] = useState()
  const [MAData, setMAData] = useState(minimumWage)
  const [WRAData, setWRAData] = useState(fmwAllowance)
  const [ERAData, setERAData] = useState(fmwAllowance)

  function AlertDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <React.Fragment>
        <Button onClick={handleClickOpen} sx={{ color: '#000000' }}>
          <InfoIcon />
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"2024 Wages and Allowances Data"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Hennings Lawyers' Modern Awards Lookup Tool is currently sourcing 2024 wage rates, wage related allowances and expense related allowances directly from the Fair Work Commission's Modern Awards Database API. Please note, we are not affiliated with the Fair Work Commission.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  function PrintPage() {
    function handlePrint() {
      window.print()
    }
    return (
      <ThemeProvider theme={greyTheme}>
        <IconButton aria-label="print" onClick={handlePrint} sx={{ color: '#000000' }}>
          <LocalPrintshopIcon />
        </IconButton>
      </ThemeProvider>
    )
  }

  const getMAList = async () => {
    setLoading(true)
    await axios.get(`https://api.hennings.com.au/hl-malt/all?api=${apiKey}`).then((allma) => {
      if (allma) {
        setMAList(allma.data.sort((a, b) => a.name.localeCompare(b.name)))
        setLoading(false)
      } else {
        setMAList([])
        setLoading(false)
      }
    })
  }

  async function getFMWData() {
    await userID();
    await axios.get(`https://api.hennings.com.au/hl-malt/cmrupdatecy?api=${apiKey}&macode=MA000010&year=${thisYear}`).then((madata) => {
      console.log("Getting C13 data from MA000010 to set FMW ")
      console.log("Retrieved C13 data from MA000010")
      const newmap = madata.data.map(mac => mac).filter((v) => (v.classification_fixed_id === 313 && v.operative_from === thisYear + "-07-01"))
      setC13({
        operative_from: newmap[0].operative_from,
        base_rate: newmap[0].base_rate,
        calculated_rate: newmap[0].calculated_rate,
      })
    });
  }

  async function getMAData(macode) {
    await userID();
    await axios.get(`https://api.hennings.com.au/hl-malt/cmrupdatecy?api=${apiKey}&macode=${macode}&year=${thisYear}`).then((madata) => {
      console.log("Getting modern award classifications and rates for " + macode)
      console.log("Retrieved modern award classifications and rates.")
      setMAData(madata.data.sort((a, b) => a.classification_fixed_id - b.classification_fixed_id))
    });
    await axios.get(`https://api.hennings.com.au/hl-malt/wraupdatecy?api=${apiKey}&macode=${macode}&year=${thisYear}`).then((wradata) => {
      console.log("Getting modern work related allowances for " + macode)
      console.log("Retrieved work related allowances.")
      setWRAData(wradata.data.sort((a, b) => a.classification_fixed_id - b.classification_fixed_id))
    });
    await axios.get(`https://api.hennings.com.au/hl-malt/eraupdatecy?api=${apiKey}&macode=${macode}&year=${thisYear}`).then((eradata) => {
      console.log("Getting modern expense related allowances for " + macode)
      console.log("Retrieved expense related allowances.")
      setERAData(eradata.data.sort((a, b) => a.classification_fixed_id - b.classification_fixed_id))
    });
  }

  const handleChangeMA = async (event) => {
    setLoading(true)
    const mac = event.target.value
    setMACode(mac)
    await getMAData(mac)
    setLoading(false)
  };

  function MATable() {
    if (MACode !== "MA000000" && MAData) {
      return (
        <TableBody>
          {MAData.filter((row) => row.base_rate_type !== "Ordinary hourly").map((row) => (
            <TableRow
              key={row.classification_fixed_id}>
              <TableCell component="th" scope="row">
                {row.fullclassification}
              </TableCell>
              <TableCell align="center">{row.base_rate_type === "Hourly" ? <NumericFormat value={parseFloat(row.base_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : row.calculated_rate_type === "Hourly" ? <NumericFormat value={parseFloat(row.calculated_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : "-"}</TableCell>
              {/* <TableCell align="center">{row.base_rate_type === "Daily" ? <NumericFormat value={parseFloat(row.base_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : row.calculated_rate_type === "Daily" ? <NumericFormat value={parseFloat(row.calculated_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : "-"}</TableCell> */}
              <TableCell align="center">{row.base_rate_type === "Weekly" ? <NumericFormat value={parseFloat(row.base_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : row.calculated_rate_type === "Weekly" ? <NumericFormat value={parseFloat(row.calculated_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : "-"}</TableCell>
              <TableCell align="center">{row.base_rate_type === "Annual" ? <NumericFormat value={parseFloat(row.base_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : row.calculated_rate_type === "Annual" ? <NumericFormat value={parseFloat(row.calculated_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      )
    }
  }

  function WRATable() {
    if (MACode !== "MA000000" && WRAData) {
      return (
        <TableBody>
          {WRAData.map((row) => (
            <TableRow
              key={row.wage_allowance_fixed_id}>
              <TableCell component="th" scope="row">
                {row.fullallowance}
              </TableCell>
              <TableCell align="center">{row.allowance_amount > 0 ? <NumericFormat value={parseFloat(row.allowance_amount).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : "-"}</TableCell>
              <TableCell align="center">{row.payment_frequency}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      )
    }
  }

  function ERATable() {
    if (MACode !== "MA000000" && ERAData) {
      return (
        <TableBody>
          {ERAData.map((row) => (
            <TableRow
              key={row.expense_allowance_fixed_id}>
              <TableCell component="th" scope="row">
                {row.fullallowance}
              </TableCell>
              <TableCell align="center">{row.allowance_amount > 0 ? <NumericFormat value={parseFloat(row.allowance_amount).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /> : "-"}</TableCell>
              <TableCell align="center">{row.payment_frequency}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      )
    }
  }

  async function userID() {
    fetch("https://api.ipdata.co?api-key=87688cf3bc5b30796670733c7f47113634abf57c340cd349bc1f6ccb")
      .then(response => {
        return response.json();
      }, "jsonp")
      .then(res => {
        const idip = res.ip
        axios.get(`https://api.hennings.com.au/hl-malt/id?api=${apiKey}&id=${idip}`)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 6000)

    setC13(getFMWData())
    getMAList()
    console.log("The minimum wage from MA000010 is: " + c13)

    return () => {
      cancel = true;
    }
  }, []);

  return (
    <>
      <Box component="section" alignItems="center" sx={{ p: 1 }}>
        <Grid
          mb={2}
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={4} align="left">
            <Link target="_blank" href="https://hennings.com.au/" rel="noopener" underline="none">
              <img src="/HenningsLawyers.png" alt="Hennings Lawyers" style={{ maxWidth: '195px' }} />
            </Link>
            <Typography ml={6} sx={{ fontSize: '50%' }}>(02) 9139 8800</Typography>
            <Typography ml={6} sx={{ fontSize: '50%' }}>hello@hennings.com.au</Typography>
          </Grid>
          <Grid item xs={4} align="center">

          </Grid>
          <Grid item xs={4} align="right">
            <PrintPage />
            <ThemeProvider theme={greyTheme}>
              <IconButton aria-label="info" sx={{ color: '#000000' }}>
                <AlertDialog />
              </IconButton>
            </ThemeProvider>
          </Grid>
        </Grid>
        <Grid
          mb={2}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={4} align="center">
            <ThemeProvider theme={greyTheme}>
              <Link href="https://hennings.com.au/inquiry" color="inherit" target="_blank" align="center">
                {'Ask us about a free wage compliance review.'}
              </Link>
            </ThemeProvider>
          </Grid>
        </Grid>
        <FormControl sx={{ mt: 2, mb: 3, width: '100%' }} variant="outlined" size="small" color="grey">
          <InputLabel id="ma-selector-label">Modern Award</InputLabel>
          <Select
            labelId="ma-selector-label"
            id="ma-selector"
            defaultValue={MACode ? MACode : "MA000000"}
            label="Modern Award"
            onChange={handleChangeMA}
            disabled={loading}
          >
            <MenuItem value="MA000000">
              <em>{minimumWage.fullclassification}</em>
            </MenuItem>
            {MAList.map(ma => {
              return (<MenuItem value={ma.code} key={ma.award_id}>{ma.name} ({ma.code}) </MenuItem>)
            })}
          </Select>
        </FormControl>

        {loading ? (
          <>
            <TableContainer mt={2} sx={{ width: '100%' }} component={Paper}>
              <Typography mb={2} sx={{ fontSize: '120%', fontWeight: '600' }} align="center">Wage Rates at 1 July 2024</Typography>
              <Table sx={{ width: '100%' }} aria-label="classifications and rates">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Classification</b></TableCell>
                    <TableCell align="center"><b>Hourly</b></TableCell>
                    {/* <TableCell align="center"><b>Daily</b></TableCell> */}
                    <TableCell align="center"><b>Weekly</b></TableCell>
                    <TableCell align="center"><b>Annual</b></TableCell>
                    {/*                     <TableCell align="center"><b>Hourly C</b></TableCell>
                    <TableCell align="center"><b>Daily C</b></TableCell>
                    <TableCell align="center"><b>Weekly C</b></TableCell>
                    <TableCell align="center"><b>Annual C</b></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Loading</TableCell>
                    <TableCell align="center">Loading</TableCell>
                    {/* <TableCell align="center">Loading</TableCell> */}
                    <TableCell align="center">Loading</TableCell>
                    <TableCell align="center">Loading</TableCell>
                    {/*                     <TableCell align="center">Loading</TableCell>
                    <TableCell align="center">Loading</TableCell>
                    <TableCell align="center">Loading</TableCell>
                    <TableCell align="center">Loading</TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
              <Typography mb={2} mt={3} variant="h3" sx={{ fontSize: '120%', fontWeight: '600' }} align="center">Work Related Allowances at 1 July 2024</Typography>
              <Table mt={2} sx={{ width: '100%' }} aria-label="wage related allowances">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Allowance</b></TableCell>
                    <TableCell align="center"><b>Amount</b></TableCell>
                    <TableCell align="center"><b>Frequency</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Loading</TableCell>
                    <TableCell align="center">Loading</TableCell>
                    <TableCell align="center">Loading</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography mb={2} mt={3} variant="h3" sx={{ fontSize: '120%', fontWeight: '600' }} align="center">CPI Rates for Expense Related Allowances</Typography>
              <Table mt={2} sx={{ width: '100%' }} aria-label="expense related allowances">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Allowance</b></TableCell>
                    <TableCell align="center"><b>Amount</b></TableCell>
                    <TableCell align="center"><b>Frequency</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Loading</TableCell>
                    <TableCell align="center"><b>Loading</b></TableCell>
                    <TableCell align="center"><b>Loading</b></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            {MACode !== "MA000000" ? (
              <TableContainer sx={{ width: '100%' }} component={Paper}>
                <Typography mb={2} sx={{ fontSize: '120%', fontWeight: '600' }} align="center">Wage Rates at 1 July 2024</Typography>
                <Table sx={{ width: '100%' }} aria-label="classifications and rates">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Classification</b></TableCell>
                      <TableCell align="center"><b>Hourly</b></TableCell>
                      {/* <TableCell align="center"><b>Daily</b></TableCell> */}
                      <TableCell align="center"><b>Weekly</b></TableCell>
                      <TableCell align="center"><b>Annual</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <MATable />
                </Table>
                <Typography mb={2} mt={3} variant="h3" sx={{ fontSize: '120%', fontWeight: '600' }} align="center">Work Related Allowances at 1 July 2024</Typography>
                <Table sx={{ width: '100%' }} aria-label="work related allowances">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Allowance</b></TableCell>
                      <TableCell align="center"><b>Amount</b></TableCell>
                      <TableCell align="center"><b>Frequency</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <WRATable />
                </Table>
                <Typography mb={2} mt={3} variant="h3" sx={{ fontSize: '120%', fontWeight: '600' }} align="center">CPI Rates for Expense Related Allowances</Typography>
                <Table sx={{ width: '100%' }} aria-label="expense related allowances">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>CPI Group</b></TableCell>
                      <TableCell align="center"><b>Increase %</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <ERATable />
                </Table>
              </TableContainer>
            ) : (
              <TableContainer sx={{ width: '100%' }} component={Paper}>
                <Typography mb={2} sx={{ fontSize: '120%', fontWeight: '600' }} align="center">Wage Rates at 1 July 2024</Typography>
                <Table sx={{ width: '100%' }} aria-label="classifications and rates">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Classification</b></TableCell>
                      <TableCell align="center"><b>Hourly</b></TableCell>
                      {/* <TableCell align="center"><b>Daily</b></TableCell> */}
                      <TableCell align="center"><b>Weekly</b></TableCell>
                      <TableCell align="center"><b>Annual</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Award/Agreement Free
                      </TableCell>
                      <TableCell align="center"><NumericFormat value={parseFloat(c13.calculated_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /></TableCell>
                      <TableCell align="center"><NumericFormat value={parseFloat(c13.base_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" /></TableCell>
                      {/* <TableCell align="center">{c13.operative_from === "2023-07-01" ? <NumericFormat value={parseFloat(c13.base_rate * 1.0375).toFixed(1)} displayType="text" thousandSeparator={true} prefix="$" /> : <NumericFormat value={parseFloat(c13.base_rate).toFixed(2)} displayType="text" thousandSeparator={true} prefix="$" />}</TableCell> */}
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography mb={2} mt={3} variant="h3" sx={{ fontSize: '120%', fontWeight: '600' }} align="center">Work Related Allowances at 1 July 2024</Typography>
                <Table sx={{ width: '100%' }} aria-label="work related allowances">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Allowance</b></TableCell>
                      <TableCell align="center"><b>Amount</b></TableCell>
                      <TableCell align="center"><b>Frequency</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography mb={2} mt={3} variant="h3" sx={{ fontSize: '120%', fontWeight: '600' }} align="center">Expense Related Allowances at 1 July 2024</Typography>
                <Table sx={{ width: '100%' }} aria-label="expense related allowances">
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Allowance</b></TableCell>
                      <TableCell align="center"><b>Amount</b></TableCell>
                      <TableCell align="center"><b>Frequency</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <ERATable />
                    <TableRow>
                      <TableCell>-</TableCell>
                      <TableCell align="center">-</TableCell>
                      <TableCell align="center">-</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>
    </>
  );
}
